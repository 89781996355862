import React from 'react';
import classes from './confirmStartDate.css';
import { object, func } from 'prop-types';
import TreeFormDate from 'src/components/TreeForm/TreeFormDate';
import { treeFormDefaultDates } from 'src/constants/dateRanges';
import useFormValidation from 'src/util/useFormValidation';
import treeFormDescriptions from 'src/constants/treeFormDescriptions.js';
import store from 'src/store';
import actions from 'src/store/actions/app';
import { validateDateStartAndEnd } from 'src/components/TreeForm/validationRules';

import { Button, FormField } from 'fronds/Controllers';

const { dispatch } = store;
const confirmDatesValidationRules = new Map();
confirmDatesValidationRules.set('effectiveDateStart', validateDateStartAndEnd);
confirmDatesValidationRules.set('effectiveDateEnd', validateDateStartAndEnd);

function ConfirmDateComponent(props) {
    const { onSubmit, onCancel, dates } = props;
    let [rule, setRule, formState] = useFormValidation(
        {
            effectiveDateEnd: dates.effectiveDateEnd,
            effectiveDateStart: dates.effectiveDateStart
        },
        confirmDatesValidationRules
    );

    return (
        <form className={classes.confirmStartDate}>
            <div className={classes.note}>
                <h3>Please confirm the dates below:</h3>
                <p>
                    Note: A start date in the past will apply a rule
                    retroactively.
                </p>
            </div>
            <FormField
                label="Start Date:"
                description={treeFormDescriptions.effectiveDateStart}
            >
                <TreeFormDate
                    currentRule={rule}
                    setCurrentRule={newRule => {
                        setRule({ ...rule, ...newRule });
                    }}
                    getFormat={treeFormDefaultDates.getStartFormat}
                    name="effectiveDateStart"
                    initialDate={treeFormDefaultDates.initialStartDate}
                    formState={formState}
                />
            </FormField>
            <FormField label="End Date:">
                <TreeFormDate
                    currentRule={rule}
                    setCurrentRule={newRule => {
                        setRule({ ...rule, ...newRule });
                    }}
                    name="effectiveDateEnd"
                    getFormat={treeFormDefaultDates.getEndFormat}
                    initialDate={treeFormDefaultDates.initialEndDate}
                    formState={formState}
                />
            </FormField>
            <div className={classes.controls}>
                {formState.messages.length > 0 ? (
                    <div className={classes.errorMessage}>
                        <p> {formState.messages.join(', ')}</p>
                    </div>
                ) : null}
                <Button onClick={onCancel}>Cancel</Button>
                <Button
                    type="submit"
                    disabled={!formState.isValid}
                    onClick={() => onSubmit(rule)}
                >
                    Continue
                </Button>
            </div>
        </form>
    );
}

ConfirmDateComponent.propTypes = {
    onSubmit: func,
    onCancel: func,
    dates: object
};

function confirmStartDate(dates) {
    let resolve;
    let reject;
    const promise = new Promise((r, rej) => {
        resolve = r;
        reject = rej;
    });
    function onCancel() {
        dispatch(actions.setConfirmationDialogue(null));
        reject();
    }

    return {
        title: 'Confirm rule date range',
        children: (
            <ConfirmDateComponent
                dates={dates}
                onSubmit={rule => {
                    dispatch(actions.setConfirmationDialogue(null));
                    resolve(rule);
                }}
                onCancel={onCancel}
            />
        ),
        promise: promise,
        onCancel: onCancel
    };
}

export default confirmStartDate;
