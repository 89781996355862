import React from 'react';
import classes from './addSpiffForm.css';
import DropdownSelectWrapper from 'src/components/DropdownSelectWrapper';
import useFormValidation from 'src/util/useFormValidation';
import validationRules from './validationRules';
import appActions from 'src/store/actions/app';
import createSpiffDescriptions from 'src/constants/createSpiffDescriptions';
import { useDispatch } from 'react-redux';
import { any, func, arrayOf, shape, number, string } from 'prop-types';

import { Button, FormField } from 'fronds/Controllers';

function AddSpiffForm(props) {
    const { payPeriodIds, onSubmit, prevSpiff = null } = props;

    const dispatch = useDispatch();

    const initialState = {
        vendorCode: '',
        payPeriodId: payPeriodIds ? payPeriodIds[0].value : 0,
        beneficiaryUserId: '',
        description: '',
        amount: 0
    };

    const [newSpiff, setNewSpiff, formState] = useFormValidation(
        prevSpiff ? prevSpiff : initialState,
        validationRules
    );

    function onPayPeriodChange(payPeriodId) {
        setNewSpiff({ ...newSpiff, payPeriodId: payPeriodId.value });
    }

    function onFormChange(ev, value) {
        const newForm = { ...newSpiff };
        newForm[ev.target.name] = ev.target[value];
        setNewSpiff(newForm);
    }

    function submit() {
        onSubmit(newSpiff);
    }

    return (
        <form className={classes.root}>
            <FormField
                label="Vendor:"
                description={createSpiffDescriptions.vendorCode}
            >
                <input
                    name="vendorCode"
                    onChange={val => onFormChange(val, 'value')}
                    value={newSpiff.vendorCode}
                    type="text"
                />
            </FormField>
            <FormField
                label="Pay Period:"
                description={createSpiffDescriptions.payPeriodId}
            >
                {prevSpiff ? (
                    <span className={classes.readOnly}>
                        {newSpiff.payPeriodDescription}
                    </span>
                ) : (
                    <DropdownSelectWrapper
                        listItems={payPeriodIds}
                        selectedValues={
                            payPeriodIds.find(
                                opt => opt.value === newSpiff.payPeriodId
                            ) || payPeriodIds[0]
                        }
                        onSelectionChange={onPayPeriodChange}
                    />
                )}
            </FormField>
            <FormField
                label="Salesperson Email Address:"
                description={createSpiffDescriptions.beneficiaryUserId}
            >
                {prevSpiff ? (
                    <span className={classes.readOnly}>
                        {newSpiff.beneficiaryUserId}
                    </span>
                ) : (
                    <input
                        name="beneficiaryUserId"
                        onChange={val => onFormChange(val, 'value')}
                        value={newSpiff.beneficiaryUserId}
                        type="text"
                    />
                )}
            </FormField>
            <FormField
                label="Description:"
                description={createSpiffDescriptions.description}
            >
                <input
                    name="description"
                    onChange={val => onFormChange(val, 'value')}
                    value={newSpiff.description}
                    type="text"
                />
            </FormField>
            <FormField
                label="Amount:"
                description={createSpiffDescriptions.amount}
            >
                <input
                    name="amount"
                    onChange={val => onFormChange(val, 'value')}
                    value={newSpiff.amount}
                    type="number"
                />
            </FormField>
            <div className={classes.controls}>
                {formState.messages.length > 0 ? (
                    <div className={classes.errorMessage}>
                        <p> {formState.messages.join(', ')}</p>
                    </div>
                ) : null}
                <div className={classes.formButtons}>
                    <Button
                        onClick={() =>
                            dispatch(appActions.setConfirmationDialogue(null))
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        disabled={!formState.isValid}
                        name="submit"
                        onClick={() => submit()}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </form>
    );
}

AddSpiffForm.propTypes = {
    onSubmit: func,
    prevSpiff: any,
    payPeriodIds: arrayOf(
        shape({
            value: number,
            label: string
        })
    )
};

export default AddSpiffForm;
