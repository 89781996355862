const validationRules = new Map();

validationRules.set('description', validateDescription);
validationRules.set('vendorCode', validateVendorCode);
validationRules.set('amount', validateAmount);
validationRules.set('beneficiaryUserId', validateBeneficiaryUserId);

function validateVendorCode({ vendorCode }) {
    if (vendorCode.length > 0) {
        return { isValid: true };
    } else {
        return { isValid: false, message: 'Vendor Code required' };
    }
}

function validateBeneficiaryUserId({ beneficiaryUserId }) {
    if (beneficiaryUserId.length > 0) {
        return { isValid: true };
    } else {
        return { isValid: false, message: 'Beneficiary User required' };
    }
}

function validateDescription({ description }) {
    if (description.length > 0) {
        return { isValid: true };
    } else {
        return { isValid: false, message: 'Description required' };
    }
}

function validateAmount({ amount }) {
    if (amount > 0) {
        return { isValid: true };
    } else {
        return { isValid: false, message: 'Amount must be greater than 0' };
    }
}

export default validationRules;
