import { treeVersion } from 'src/constants';

export const newFlagTree = params => ({
    ...params,
    nodes: {
        id: treeVersion,
        nodes: {
            '1': {
                id: '1',
                data: {
                    userFields: {
                        description: 'My first node',
                        expression: 'GrossProfitPercent >= 0.80'
                    }
                },
                name: 'Criteria',
                position: [-313, -18.5],
                inputs: {
                    input: {
                        connections: []
                    }
                },
                outputs: {
                    yes: {
                        connections: []
                    },
                    no: { connections: [] }
                }
            }
        }
    }
});
