import React from 'react';
import { useDispatch } from 'react-redux';
import Trash2 from 'feather/trash-2';
import classes from './ruleDelete.css';
import { deleteRule } from 'src/store/actions/ruleAsyncActions';
import { deleteFlag } from 'src/store/actions/ruleAsyncActions';
import appActions from 'src/store/actions/app';
import { deleteRuleConfirmation } from 'src/components/Dialogue/messages.js';
import { number, func, string } from 'prop-types';
import treeTypes from 'src/constants/treeTypes';
import { Button } from 'fronds/Controllers';

function DefaultWrapper(props) {
    return <div {...props} />;
}

function RuleDelete(props) {
    const dispatch = useDispatch();
    const {
        id,
        width,
        Wrapper = DefaultWrapper,
        treeType = treeTypes.rule
    } = props;
    const { setConfirmationDialogue, removeCachedRoutes } = appActions;

    function dispatchDelete() {
        dispatch(setConfirmationDialogue(null));
        treeType === treeTypes.rule
            ? dispatch(deleteRule(id))
            : dispatch(deleteFlag(id));
        dispatch(removeCachedRoutes());
    }

    function showDeleteDialog() {
        dispatch(
            setConfirmationDialogue(
                deleteRuleConfirmation(<DeleteConfirmation />)
            )
        );
    }

    const DeleteConfirmation = () => {
        return (
            <div className={classes.root}>
                <Button
                    onClick={() => dispatch(setConfirmationDialogue(false))}
                >
                    Cancel
                </Button>
                <Button onClick={() => dispatchDelete()}>Delete</Button>
            </div>
        );
    };

    return (
        <Wrapper onClick={() => showDeleteDialog()}>
            <Trash2 width={width} />
        </Wrapper>
    );
}

RuleDelete.propTypes = {
    id: number,
    width: number,
    Wrapper: func,
    treeType: string
};

export default RuleDelete;
