import { treeVersion } from 'src/constants';

export const newTree = params => ({
    ...params,
    nodes: {
        id: treeVersion,
        nodes: {
            '1': {
                id: '1',
                data: {
                    userFields: {
                        description: 'My first node',
                        expression: `1=1`
                    }
                },
                name: 'Criteria',
                position: [-313, -18.5],
                inputs: {
                    input: {
                        connections: []
                    }
                },
                outputs: {
                    yes: {
                        connections: [
                            {
                                node: '2',
                                input: 'input',
                                data: { connectionType: 'yes' }
                            }
                        ]
                    },
                    no: { connections: [] }
                }
            },
            '2': {
                id: '2',
                data: {
                    userFields: {
                        description: 'My second node',
                        expression: '100',
                        payPeriodDateReference:
                            params.commissionRuleTreeType === 'Project'
                                ? 'RuleProcessingDate'
                                : 'InvoiceDate'
                    }
                },
                name: 'Payout',
                position: [358, -14.5],
                inputs: {
                    input: {
                        connections: [
                            {
                                node: '1',
                                output: 'yes',
                                data: { connectionType: 'yes' }
                            }
                        ]
                    }
                },
                outputs: {}
            }
        }
    }
});
