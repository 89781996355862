export default {
    description: "A general description of the rule's behavior",
    effectiveDateStart:
        'The rule will only be process/evaluated in this date range. Any invoices that fall outside this range will not be eligible',
    beneficiaryType:
        'The type of user that can receive commission from this rule.\nThis field can not be edited once the rule tree has been created',
    commissionTreeType:
        'Determines whether commissions are calculated from an entire invoice or by invoice lines, and if commission from this rule can be added to commission from other rules.\nThis field can not be edited once the rule tree has been created',
    payoutCanBeAddedToOtherRules:
        'If false, only the highest priority rule that results in a payout will apply. If true, payouts resulting from this rule can be added to payouts created by other higher priority rules',
    AggregateDateScope:
        'Aggregate node criteria will be applied to EACH date scope within your effective range, potentially creating payouts for each of them.',
    DaysToWaitBeforeProcessing:
        'The rule will wait this many days after a period has ended before running. This allows data to stabilize or be corrected before potentially creating payouts.',
    AggregateMaxPayoutFrequencyInDays:
        'The frequency at which a salesperson can recieve the max payout for this rule.',
    priorityIndex: 'Can only be edited by reordering items on rules page'
};
