import React from 'react';
import { string } from 'prop-types';
import { Link as NaviLink } from 'react-navi';

function Link(props) {
    let { href } = props;
    href = PUBLIC_URL + href;
    return <NaviLink {...props} href={href} />;
}

Link.propTypes = {
    href: string
};

export default Link;
