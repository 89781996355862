export const userFields = 'userFields';
export const RawDollars = 'RawDollars';
export const PercentageOfGP = 'PercentageOfGP';
export const nodeTypes = {
    Payout: 'Payout',
    /* Probably not going to use the below constants, keeping until expression node is updated*/
    dateConstraint: 'Date Constraint',
    scopeConstraint: 'Scope Constraint',
    fieldConstraint: 'Field Constraint'
};
