import moment from 'moment';

const validationRules = new Map();

validationRules.set('effectiveDateStart', validateDateStartAndEnd);
validationRules.set('effectiveDateEnd', validateDateStartAndEnd);
validationRules.set('description', validateDescription);

export function validateDateStartAndEnd({
    effectiveDateEnd,
    effectiveDateStart,
    form
}) {
    if (
        moment(form.effectiveDateStart).isAfter(moment(form.effectiveDateEnd))
    ) {
        return { isValid: false, message: 'Start date is after end date.' };
    }

    // Null dates are valid. If a date is undefined, it is usually
    // because the current property being tested isn't
    // the one being evaluated
    if (effectiveDateEnd != null && !moment(effectiveDateEnd).isValid()) {
        return { isValid: false, message: 'Date is not valid' };
    }

    if (effectiveDateStart != null && !moment(effectiveDateStart).isValid()) {
        return { isValid: false, message: 'Date is not valid' };
    }

    return { isValid: true };
}

function validateDescription({ description }) {
    if (description.length > 0) {
        return { isValid: true };
    } else {
        return { isValid: false, message: 'Description required' };
    }
}

export default validationRules;
