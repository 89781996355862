import React from 'react';
import Globe from 'feather/globe';
import Box from 'feather/box';

const defaultSize = '28px';

export const nodeHeaders = {
    'Scope Constraint': <Globe height={defaultSize} width={defaultSize} />,
    Group: <Box height={defaultSize} width={defaultSize} />
};
