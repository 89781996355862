import React from 'react';
import { useNavigation } from 'react-navi';
import store from 'src/store';
import userRoles from 'src/constants/userRoles';
import classes from './tableRowIcons.css';
import {
    ArrowRight,
    Award,
    Check,
    Copy,
    Download,
    Edit,
    List,
    Slash,
    Trash,
    Unlock,
    X
} from 'react-feather';
import Link from 'src/components/Link';
import { initOverride } from 'src/store/actions/tableAsyncActions';
import { useDispatch, useSelector } from 'react-redux';
import {
    duplicateRule,
    duplicateFlag
} from 'src/store/actions/ruleAsyncActions';
import RuleDelete from 'src/components/Rete/Toolbar/ruleDelete';
import {
    editSpiffForm,
    deleteSpiffConfirmation,
    deleteBranchConfirmation
} from 'src/components/Dialogue/messages';
import appActions from 'src/store/actions/app';
import {
    approveOverrideInit,
    declineOverride,
    deleteOverride,
    lockPayPeriod,
    dismissFlag,
    exportSalesPayPeriod,
    updateSpiff,
    deleteSpiff,
    deleteBranch
} from 'src/store/actions/tableAsyncActions';
import IconTray from './iconTray';
import {
    commissionSearchType,
    overrideSearchType,
    isFullyPaid,
    flagSearchType
} from 'src/constants/invoiceFilters';
import Tooltip from 'src/util/tooltip';
import treeTypes from 'src/constants/treeTypes';
import AddSpiffForm from 'src/rootComponents/VendorSpiffs/addSpiffForm';
import { Button } from 'fronds/Controllers';

const iconSize = 20;
const tooltipProps = { darkTheme: true, hideArrow: true, placement: 'bottom' };

// ICON SETS
// IconTray acts as a wrapper that takes in props, and renders its children while spreading props.row into their props.

export function OverrideIcons(props) {
    return (
        <IconTray {...props} className={classes.actionIcons}>
            <ApproveIcon />
            <DeclineIcon />
            <CancelIcon />
        </IconTray>
    );
}

export function OverrideIconsSales(props) {
    return (
        <IconTray {...props} className={classes.actionIcons}>
            <CancelIcon />
        </IconTray>
    );
}

export function InvoiceIcons(props) {
    return (
        <IconTray {...props} className={classes.actionIcons}>
            <OverrideIcon />
            <InvoiceIcon />
        </IconTray>
    );
}

export function ProjectsIcons(props) {
    return (
        <IconTray {...props} className={classes.actionIcons}>
            <ProjectInvoicesIcon />
        </IconTray>
    );
}

export function FlaggedInvoiceIcons(props) {
    return (
        <IconTray {...props} className={classes.actionIcons}>
            <DismissFlagIcon />
            <InvoiceIcon />
        </IconTray>
    );
}

export function RuleIcons(props) {
    return (
        <IconTray {...props} className={classes.actionIcons}>
            <DuplicateIcon />
            <DeleteRuleIcon />
            <ViewRuleIcon />
        </IconTray>
    );
}

export function FlagIcons(props) {
    return (
        <IconTray {...props} className={classes.actionIcons}>
            <DuplicateFlagRuleIcon />
            <DeleteFlagIcon />
            <ViewFlagIcon />
        </IconTray>
    );
}

export function CommissionIconsSales(props) {
    return (
        <IconTray {...props} className={classes.actionIcons}>
            <ExportSalesPayPeriodIcon />
            <ViewPayrollDetailsIcon />
        </IconTray>
    );
}

export function CommissionIcons(props) {
    return (
        <IconTray {...props} className={classes.actionIcons}>
            <LockIcon />
        </IconTray>
    );
}

export function VendorSpiffIcons(iconProps) {
    const roles = store.getState().user.roles;
    return (roles.includes(userRoles.admin) ||
        roles.includes(userRoles.payroll)) &&
        !iconProps.row.isLocked ? (
        <IconTray {...iconProps} className={classes.actionIcons}>
            <VendorEditIcon />
            <VendorDeleteIcon />
        </IconTray>
    ) : null;
}

export function InvoiceStatuses(props) {
    return (
        <IconTray {...props} className={classes.statusIcons}>
            <InvoiceCommissionStatus />
            <InvoiceOverrideStatus />
            <InvoiceFlagStatus />
            <FullyPaidStatus />
        </IconTray>
    );
}

export function ProjectStatuses(props) {
    return (
        <IconTray {...props} className={classes.statusIcons}>
            <ProjectCommissionStatus />
        </IconTray>
    );
}

export function BranchSettingsIcons(iconProps) {
    const roles = store.getState().user.roles;
    return roles.includes(userRoles.admin) ? (
        <IconTray {...iconProps} className={classes.actionIcons}>
            <BranchDeleteIcon />
        </IconTray>
    ) : null;
}

// ICONS
export function InvoiceCommissionStatus(row) {
    const activeClasses = [classes.rowStatus, classes.selectedStatusIcon];
    const pendingClasses = [
        classes.rowStatus,
        classes.selectedStatusIcon,
        classes.pendingStatusIcon
    ];
    const { invoiceLetterMap } = useSelector(state => state.app);

    const statusBarClass = row[invoiceLetterMap.HasPendingCommission]
        ? pendingClasses.join(' ')
        : row[invoiceLetterMap.HasCommission]
        ? activeClasses.join(' ')
        : classes.rowStatusDisabled;

    let title = commissionSearchType.label;
    if (row[invoiceLetterMap.HasPendingCommission]) {
        title += ' (pending)';
    }

    return (
        <div className={statusBarClass} title={title}>
            {commissionSearchType.icon}
        </div>
    );
}

export function ProjectCommissionStatus(row) {
    const activeClasses = [classes.rowStatus, classes.selectedStatusIcon];
    const pendingClasses = [
        classes.rowStatus,
        classes.selectedStatusIcon,
        classes.pendingStatusIcon
    ];

    let statusProps = {
        statusBarClass: classes.rowStatusDisabled,
        title: commissionSearchType.labelFalse
    };

    if (row.hasPendingCommission) {
        statusProps = {
            statusBarClass: pendingClasses,
            title: commissionSearchType.labelPending
        };
    } else if (row.hasCommission) {
        statusProps = {
            statusBarClass: activeClasses,
            title: commissionSearchType.label
        };
    }

    return <div {...statusProps}>{commissionSearchType.icon}</div>;
}

export function InvoiceOverrideStatus(row) {
    const activeClasses = [classes.rowStatus, classes.selectedStatusIcon];
    const pendingClasses = [
        classes.rowStatus,
        classes.selectedStatusIcon,
        classes.pendingStatusIcon
    ];
    const { invoiceLetterMap } = useSelector(state => state.app);

    const statusBarClass = row[invoiceLetterMap.HasPendingOverride]
        ? pendingClasses.join(' ')
        : row[invoiceLetterMap.HasOverride]
        ? activeClasses.join(' ')
        : classes.rowStatusDisabled;

    let title = overrideSearchType.label;
    if (row[invoiceLetterMap.HasPendingOverride]) {
        title += ' (pending)';
    }

    return (
        <div className={statusBarClass} title={title}>
            {overrideSearchType.icon}
        </div>
    );
}

export function InvoiceFlagStatus(row) {
    const activeClasses = [classes.rowStatus, classes.selectedStatusIcon];
    const pendingClasses = [
        classes.rowStatus,
        classes.selectedStatusIcon,
        classes.pendingStatusIcon
    ];
    const { invoiceLetterMap } = useSelector(state => state.app);

    const statusBarClass = row[invoiceLetterMap.HasDismissedFlag]
        ? pendingClasses.join(' ')
        : row[invoiceLetterMap.HasFlag]
        ? activeClasses.join(' ')
        : classes.rowStatusDisabled;

    let title = flagSearchType.label;
    if (row[invoiceLetterMap.HasDismissedFlag]) {
        title += ' (dismissed)';
    }

    return (
        <div className={statusBarClass} title={title}>
            {flagSearchType.icon}
        </div>
    );
}

export function FullyPaidStatus(row) {
    const activeClasses = [classes.rowStatus, classes.selectedStatusIcon];
    const { invoiceLetterMap } = useSelector(state => state.app);

    const statusBarClass = row[invoiceLetterMap.IsFullyPaid]
        ? activeClasses.join(' ')
        : classes.rowStatusDisabled;

    return (
        <div className={statusBarClass} title={isFullyPaid.label}>
            {isFullyPaid.icon}
        </div>
    );
}

function OverrideIcon(row) {
    const dispatch = useDispatch();
    const { invoiceLetterMap } = useSelector(state => state.app);
    const { roles } = useSelector(state => state.user);

    let isSalesOnly = roles
        ? roles.includes(userRoles.sales) && roles.length === 1
        : true;

    const hasOverride = row[invoiceLetterMap.HasOverride];

    function onClick() {
        dispatch(initOverride(row));
    }

    //Only restricting override request if user is not admin/gm, and invoice already has override
    return !isSalesOnly || (isSalesOnly && !hasOverride) ? (
        <Tooltip {...tooltipProps} tooltip={'Override'}>
            <button className={classes.icon} onClick={onClick}>
                <Award width={iconSize} />
            </button>
        </Tooltip>
    ) : null;
}

function InvoiceIcon(row) {
    const { invoiceLetterMap } = useSelector(state => state.app);
    const navigation = useNavigation();

    const invoiceId = row[invoiceLetterMap.InvoiceNumber];
    const orderNumberId = row[invoiceLetterMap.OrderNumber];

    const invoiceUrl = invoiceId
        ? `/invoices/invoice/?id=${invoiceId}&orderNumber=${orderNumberId}`
        : null;
    const searchParams = new URLSearchParams(navigation.lastRoute.url.search);

    // Set current row as position to scroll to when navigating back to invoices
    const pathname = navigation.lastRoute.url.pathname;
    if (navigation.lastRoute.url.query.scrollId) {
        searchParams.set('scrollId', row[invoiceLetterMap.InvoiceNumber]);
    } else {
        searchParams.append('scrollId', row[invoiceLetterMap.InvoiceNumber]);
    }

    function onClick() {
        navigation.history.replace(`${pathname}?${searchParams.toString()}`);
    }

    return (
        <Tooltip {...tooltipProps} tooltip={'Details'}>
            <Link
                onClick={onClick}
                key={invoiceId}
                className={classes.icon}
                href={invoiceUrl}
            >
                <ArrowRight width={iconSize} />
            </Link>
        </Tooltip>
    );
}

function ProjectInvoicesIcon(row) {
    const { projectNumber } = row;
    const navigation = useNavigation();

    const searchParams = new URLSearchParams(navigation.lastRoute.url.search);

    // Set current row as position to scroll to when navigating back to projects
    const pathname = navigation.lastRoute.url.pathname;
    if (navigation.lastRoute.url.query.scrollId) {
        searchParams.set('scrollId', projectNumber);
    } else {
        searchParams.append('scrollId', projectNumber);
    }

    function onClick() {
        navigation.history.replace(`${pathname}?${searchParams.toString()}`);
    }

    return (
        <Tooltip {...tooltipProps} tooltip={'Invoices'}>
            <Link
                onClick={onClick}
                key={projectNumber}
                className={classes.icon}
                href={`/invoices?aoSearch=${projectNumber}`}
            >
                <List width={iconSize} />
            </Link>
        </Tooltip>
    );
}

function LockIcon(row) {
    const dispatch = useDispatch();

    let {
        payPeriodCanBeLocked,
        isLocked,
        payPeriodId,
        salespersonBranch
    } = row;
    let { canBeLocked } = payPeriodCanBeLocked;

    async function onClickLock() {
        await dispatch(lockPayPeriod(payPeriodId, salespersonBranch, row));
    }

    return canBeLocked ? (
        <Tooltip {...tooltipProps} tooltip={'Lock Pay Period'}>
            <button className={classes.icon} onClick={() => onClickLock()}>
                <Unlock width={iconSize} />
            </button>
        </Tooltip>
    ) : !isLocked ? (
        <Tooltip
            {...tooltipProps}
            tooltip={`Pay period can not be locked:
                ${payPeriodCanBeLocked.validationMessages.join(' & ')}.`}
        >
            <button className={classes.iconDisabled}>
                <Unlock width={iconSize} className={classes.unlockIcon} />
            </button>
        </Tooltip>
    ) : null;
}

function ViewPayrollDetailsIcon(row) {
    const ppid = row['payPeriodId'];
    const branch = row['salespersonBranch'];
    const userId = row['beneficiaryUserId'];
    let payrollDetailURL = ppid ? '/commissions/payout/?id=' + ppid : null;
    if (branch) payrollDetailURL += '&branch=' + branch;
    if (userId) payrollDetailURL += '&userId=' + userId;
    return (
        <Tooltip {...tooltipProps} tooltip={'Details'}>
            <Link key={ppid} className={classes.icon} href={payrollDetailURL}>
                <ArrowRight width={iconSize} />
            </Link>
        </Tooltip>
    );
}

function ExportSalesPayPeriodIcon(row) {
    const dispatch = useDispatch();
    const {
        payPeriodId,
        payPeriodDescription,
        beneficiaryUserId,
        beneficiaryName
    } = row;

    async function onClickExport() {
        await dispatch(
            exportSalesPayPeriod(
                payPeriodId,
                payPeriodDescription,
                beneficiaryUserId,
                beneficiaryName
            )
        );
    }

    return (
        <Tooltip {...tooltipProps} tooltip={'Export'}>
            <button className={classes.icon} onClick={() => onClickExport()}>
                <Download width={iconSize} />
            </button>
        </Tooltip>
    );
}

function ViewRuleIcon(row) {
    const ruleId = row.id;
    const ruleURL = ruleId ? '/commission-rules/tree/?id=' + ruleId : null;

    return (
        <Tooltip {...tooltipProps} tooltip={'View'}>
            <Link className={classes.icon} href={ruleURL}>
                <ArrowRight width={iconSize} />
            </Link>
        </Tooltip>
    );
}

function ViewFlagIcon(row) {
    const flagId = row.id;
    const flagURL = flagId ? '/flag-rules/tree/?id=' + flagId : null;

    return (
        <Tooltip {...tooltipProps} tooltip={'View'}>
            <Link className={classes.icon} href={flagURL}>
                <ArrowRight width={iconSize} />
            </Link>
        </Tooltip>
    );
}

function DeleteRuleIcon(row) {
    const ruleId = row.id;

    return (
        <Tooltip {...tooltipProps} tooltip={'Delete'}>
            <button className={classes.icon}>
                <RuleDelete id={ruleId} width={iconSize} height={iconSize} />
            </button>
        </Tooltip>
    );
}

function DeleteFlagIcon(row) {
    const flagId = row.id;

    return (
        <Tooltip {...tooltipProps} tooltip={'Delete'}>
            <button className={classes.icon}>
                <RuleDelete
                    id={flagId}
                    width={iconSize}
                    height={iconSize}
                    treeType={treeTypes.flag}
                />
            </button>
        </Tooltip>
    );
}

function ApproveIcon(row) {
    const { requestedDollarAmount, isLocked } = row;
    const dispatch = useDispatch();

    async function onClick() {
        await dispatch(approveOverrideInit(requestedDollarAmount, row));
    }

    return !isLocked ? (
        <Tooltip {...tooltipProps} tooltip={'Approve'}>
            <button onClick={onClick} className={classes.icon}>
                <Check width={iconSize} className={classes.approveIcon} />
            </button>
        </Tooltip>
    ) : (
        <Tooltip {...tooltipProps} tooltip={`Invoice is Locked`}>
            <button disabled className={classes.iconDisabled}>
                <Check width={iconSize} />
            </button>
        </Tooltip>
    );
}

function DeclineIcon(row) {
    const { id, isLocked } = row;

    const dispatch = useDispatch();

    async function onClick() {
        await dispatch(declineOverride(id, row));
    }

    return !isLocked ? (
        <Tooltip {...tooltipProps} tooltip={'Decline'}>
            <button className={classes.icon} onClick={onClick}>
                <X width={iconSize} className={classes.declineIcon} />
            </button>
        </Tooltip>
    ) : (
        <Tooltip {...tooltipProps} tooltip={`Invoice is Locked`}>
            <button disabled className={classes.iconDisabled}>
                <X width={iconSize} />
            </button>
        </Tooltip>
    );
}

function CancelIcon(row) {
    const dispatch = useDispatch();

    const { id, dispositionDetail, beneficiaryUserId, isLocked } = row;

    const { details } = useSelector(state => state.user);
    const currentUser = details ? details.email : null;

    async function onClick() {
        await dispatch(deleteOverride(id, row));
    }

    const isPendingAndOwnedByUser =
        dispositionDetail &&
        dispositionDetail.label === 'Pending' &&
        currentUser === beneficiaryUserId;

    if (!isPendingAndOwnedByUser) return null;

    return !isLocked ? (
        <Tooltip {...tooltipProps} tooltip={'Cancel'}>
            <button className={classes.icon} onClick={onClick}>
                <Slash width={iconSize} className={classes.cancelIcon} />
            </button>
        </Tooltip>
    ) : (
        <Tooltip {...tooltipProps} tooltip={`Invoice is Locked`}>
            <button disabled className={classes.iconDisabled}>
                <Slash width={iconSize} />
            </button>
        </Tooltip>
    );
}

function DismissFlagIcon(row) {
    const dispatch = useDispatch();
    const { invoiceLetterMap } = useSelector(state => state.app);

    const hasDismissedFlag = row[invoiceLetterMap.HasDismissedFlag];

    async function onClick() {
        await dispatch(dismissFlag(row));
    }
    return !hasDismissedFlag ? (
        <Tooltip {...tooltipProps} tooltip={'Dismiss Flags'}>
            <button className={classes.icon} onClick={onClick}>
                <X width={iconSize} className={classes.declineIcon} />
            </button>
        </Tooltip>
    ) : null;
}

export function DuplicateIcon(row) {
    const dispatch = useDispatch();
    return (
        <Tooltip {...tooltipProps} tooltip={'Duplicate'}>
            <button
                className={classes.icon}
                onClick={() => {
                    dispatch(duplicateRule(row.id));
                }}
            >
                <Copy width={iconSize} />
            </button>
        </Tooltip>
    );
}

export function DuplicateFlagRuleIcon(row) {
    const dispatch = useDispatch();
    return (
        <Tooltip {...tooltipProps} tooltip={'Duplicate'}>
            <button className={classes.icon}>
                <Copy
                    width={iconSize}
                    onClick={() => {
                        dispatch(duplicateFlag(row.id));
                    }}
                />
            </button>
        </Tooltip>
    );
}

export function VendorEditIcon(row) {
    const dispatch = useDispatch();

    async function onSubmit(spiff) {
        dispatch(updateSpiff(spiff));
        dispatch(appActions.setConfirmationDialogue(null));
    }

    return (
        <Tooltip {...tooltipProps} tooltip={'Edit'}>
            <button
                className={classes.icon}
                onClick={() =>
                    dispatch(
                        appActions.setConfirmationDialogue(
                            editSpiffForm(
                                <AddSpiffForm
                                    onSubmit={onSubmit}
                                    prevSpiff={row}
                                />
                            )
                        )
                    )
                }
            >
                <Edit width={iconSize} />
            </button>
        </Tooltip>
    );
}

export function VendorDeleteIcon(row) {
    const dispatch = useDispatch();

    const DeleteConfirmation = row => {
        return (
            <div className={classes.form}>
                <Button
                    onClick={() =>
                        dispatch(appActions.setConfirmationDialogue(null))
                    }
                >
                    Cancel
                </Button>
                <Button onClick={() => dispatch(deleteSpiff(row))}>
                    Delete
                </Button>
            </div>
        );
    };

    return (
        <Tooltip {...tooltipProps} tooltip={'Delete'}>
            <button
                className={classes.icon}
                onClick={() =>
                    dispatch(
                        appActions.setConfirmationDialogue(
                            deleteSpiffConfirmation(DeleteConfirmation(row))
                        )
                    )
                }
            >
                <Trash width={iconSize} />
            </button>
        </Tooltip>
    );
}

export function BranchDeleteIcon(row) {
    const dispatch = useDispatch();

    const DeleteConfirmation = row => {
        return (
            <div className={classes.form}>
                <Button
                    onClick={() =>
                        dispatch(appActions.setConfirmationDialogue(null))
                    }
                >
                    Cancel
                </Button>
                <Button onClick={() => dispatch(deleteBranch(row))}>
                    Delete
                </Button>
            </div>
        );
    };

    return (
        <Tooltip {...tooltipProps} tooltip={'Delete'}>
            <button
                className={classes.icon}
                onClick={() =>
                    dispatch(
                        appActions.setConfirmationDialogue(
                            deleteBranchConfirmation(DeleteConfirmation(row))
                        )
                    )
                }
            >
                <Trash width={iconSize} />
            </button>
        </Tooltip>
    );
}
