import React, { useState, useEffect } from 'react';
import { string, func, object } from 'prop-types';
import classes from './treeFormDate.css';
import moment from 'moment';
import SingleDatePicker from 'src/components/DatePicker';
import AlertCircle from 'feather/alert-circle';

import { Toggle } from 'fronds/Controllers';

TreeFormDate.defaultProps = {
    initialDate: moment().format()
};

function TreeFormDate(props) {
    const {
        name,
        setCurrentRule,
        currentRule,
        initialDate,
        getFormat,
        formState
    } = props;
    const [isDateHidden, setIsDateHidden] = useState(true);
    const [currentDate, setCurrentDate] = useState(null);
    const [focus, setFocus] = useState(false);

    function getInitialDate() {
        if (
            currentRule &&
            currentRule[name] &&
            moment(currentRule[name]).isValid()
        ) {
            setIsDateHidden(false);
            return moment(currentRule[name]);
        } else if (initialDate) {
            return moment(initialDate);
        }
    }

    useEffect(() => {
        setCurrentDate(getInitialDate());
    }, []);

    function toggleDate() {
        setIsDateHidden(isDateHidden => {
            let newDate;
            if (!isDateHidden) {
                newDate = null;
            } else {
                setFocus(true);
                newDate = getFormat(currentDate);
            }
            setCurrentRule({ ...currentRule, [name]: newDate });
            return !isDateHidden;
        });
    }

    function handleChange(date) {
        if (date && date.isValid()) {
            setCurrentDate(date);
            setCurrentRule({ ...currentRule, [name]: getFormat(date) });
        } else {
            setCurrentDate(null);
            setCurrentRule({ ...currentRule, [name]: 'Invalid date' });
            setFocus(true);
        }
    }

    function getClassName() {
        let className = '';
        if (currentDate === '') {
            className = classes.nullDate;
        }
        if (isDateHidden) {
            className += ' ' + classes.dateIsHidden;
        }
        return className;
    }

    return (
        <div className={classes.root}>
            <Toggle
                customClass={classes.toggle}
                onChange={() => toggleDate(name)}
                checked={!isDateHidden}
            />
            <div className={`${classes.datePicker} ${getClassName()}`}>
                <SingleDatePicker
                    numberOfMonths={1}
                    onDateChange={handleChange}
                    showClearDate={false}
                    date={currentDate}
                    focused={focus}
                    onFocusChange={({ focused }) => setFocus(focused)}
                    id={`${name}-datepicker`}
                    isOutsideRange={() => false}
                    transitionDuration={0}
                    hideKeyboardShortcutsPanel={true}
                    appendToBody
                />
            </div>
            <div className={classes.error}>
                {formState[name] && !formState[name].isValid ? (
                    <AlertCircle />
                ) : null}
            </div>
        </div>
    );
}

TreeFormDate.propTypes = {
    name: string.isRequired,
    setCurrentRule: func.isRequired,
    currentRule: object.isRequired,
    initialDate: string,
    getFormat: func.isRequired,
    formState: object.isRequired
};

export default TreeFormDate;
