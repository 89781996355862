import React from 'react';
import { array, object, oneOfType, string } from 'prop-types';

function IconTray(props) {
    let { children, row, className } = props;

    if (!children) {
        return null;
    }

    const renderChildren = React.Children.map(children, child => {
        return React.cloneElement(child, { ...row });
    });

    return <div className={className}>{renderChildren}</div>;
}

IconTray.propTypes = {
    children: oneOfType([array, object]),
    row: object,
    className: string
};

export default IconTray;
