export default function(searchParams, location, history) {
    // Set params equal to existing to search parameters
    let params = new URLSearchParams(location.search);
    Object.keys(searchParams).forEach(key => {
        params.set(key, searchParams[key]);
    });
    location.search = params.toString();
    history.push(location);
}

export function objectToUrlParams(newParams) {
    // Set params equal to existing to search parameters
    let params = new URLSearchParams();
    Object.keys(newParams).forEach(key => {
        params.set(key, newParams[key]);
    });
    return params.toString();
}
