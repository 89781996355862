import actions from 'src/store/actions/rule';
import { request } from 'src/util/request';
import { newTree } from 'src/components/Rete/newTree';
import { newFlagTree } from 'src/components/Rete/newFlagTree';
import axios from 'axios';
import appActions from 'src/store/actions/app';
import messageTypes from 'src/constants/messageTypes';
import navigation from 'src/util/navigation';
import { objectToUrlParams } from 'src/util/setSearchParams';
import confirmStartDate from 'src/components/ConfirmationDialogue/ConfirmStartDate';
import treeTypes from 'src/constants/treeTypes';
const isEqual = require('lodash/isEqual');

const isEmpty = require('lodash/isEmpty');

const CancelToken = axios.CancelToken;
const { setTreeStatus, setIsProcessing } = actions;
let source = CancelToken.source();

const { setConfirmRouteChange, removeCachedRoutes } = appActions;

function nodeIdToKey(treeStatus) {
    return treeStatus.reduce((accum, curr) => {
        if (accum[curr.nodeId]) {
            accum[curr.nodeId].push(curr.errorMessage);
        } else {
            accum[curr.nodeId] = [curr.errorMessage];
        }
        return accum;
    }, {});
}

export function validateTree(tree, treeType) {
    return async function(dispatch) {
        try {
            dispatch(setIsProcessing(true));
            let treeStatus;
            //TODO: Ensure flag endpoint is correct
            if (treeType === treeTypes.flag) {
                treeStatus = await request.post(
                    '/InvoiceFlagRule/validate',
                    tree,
                    {
                        cancelToken: source.token
                    }
                );
            } else {
                treeStatus = await request.post(
                    '/CommissionRuleTree/validate',
                    tree,
                    {
                        cancelToken: source.token
                    }
                );
            }
            treeStatus = nodeIdToKey(treeStatus.data.errorMessages);
            dispatch(setIsProcessing(false));
            dispatch(setTreeStatus(treeStatus));
            source.cancel();
            source = CancelToken.source();
        } catch (error) {
            dispatch(setIsProcessing(false));
            source.cancel();
            source = CancelToken.source();
        }
    };
}

export function createRule(newRule) {
    return async function() {
        let tree = newRule.nodes ? newRule : newTree(newRule);
        const response = await request.post('/CommissionRuleTree', tree);
        const { data: ruleTreeId } = response;
        navigation.navigate({
            pathname: `/commission-rules/tree/`,
            query: {
                id: ruleTreeId
            }
        });
    };
}

export function createFlag(newFlag) {
    return async function(dispatch) {
        dispatch(removeCachedRoutes());
        let tree = newFlag.nodes ? newFlag : newFlagTree(newFlag);
        const response = await request.post('/InvoiceFlagRule', tree);
        const { data: flagTreeId } = response;
        navigation.navigate({
            pathname: `/flag-rules/tree/`,
            query: {
                id: flagTreeId
            }
        });
    };
}

export function editRule(tree) {
    return async function(dispatch, getState) {
        const { id } = tree;
        await dispatch(validateTree(tree));
        const treeStatus = getState().rule.treeStatus;
        if (isEmpty(treeStatus)) {
            await request.put(`/CommissionRuleTree/${id}`, tree);
            dispatch(setConfirmRouteChange(false));
            dispatch(
                appActions.addMessage({
                    message: `Rule #${id} successfully updated`,
                    type: messageTypes.SUCCESS
                })
            );
            source.cancel();
            source = CancelToken.source();
        } else {
            dispatch(
                appActions.addMessage({
                    message: `You can't save an invalid tree. Fix the errors and then try again.`,
                    type: messageTypes.ERROR
                })
            );
            source.cancel();
            source = CancelToken.source();
        }
    };
}

export function editFlagDescription(id, description) {
    return async function(dispatch) {
        await request.put(`/InvoiceFlagRule/${id}/UpdateDescription`, {
            description: description
        });
        dispatch(setConfirmRouteChange(false));
        navigation.navigate({
            pathname: '/flag-rules/tree/',
            query: {
                id: id
            }
        });
        dispatch(
            appActions.addMessage({
                message: `Description updated!`,
                type: messageTypes.SUCCESS
            })
        );
    };
}

export function editFlag(tree) {
    return async function(dispatch, getState) {
        const { id } = tree;
        await dispatch(validateTree(tree));
        const treeStatus = getState().rule.treeStatus;
        if (isEmpty(treeStatus)) {
            await request.put(`/InvoiceFlagRule/${id}`, tree);
            dispatch(setConfirmRouteChange(false));
            dispatch(
                appActions.addMessage({
                    message: `Flag #${id} successfully updated`,
                    type: messageTypes.SUCCESS
                })
            );
            navigation.navigate({
                pathname: '/flag-rules/tree/',
                query: {
                    id: tree.id
                }
            });
            source.cancel();
            source = CancelToken.source();
        } else {
            dispatch(
                appActions.addMessage({
                    message: `You can't save an invalid tree. Fix the errors and then try again.`,
                    type: messageTypes.ERROR
                })
            );
            source.cancel();
            source = CancelToken.source();
        }
    };
}

export function deleteRule(id) {
    return async function(dispatch) {
        await request.delete(`/CommissionRuleTree/${id}`);
        navigation.navigate('/commission-rules/');
        dispatch(
            appActions.addMessage({
                message: `Rule #${id} successfully deleted`,
                type: messageTypes.SUCCESS
            })
        );
    };
}

export function deleteFlag(id) {
    return async function(dispatch) {
        dispatch(removeCachedRoutes());
        await request.delete(`/InvoiceFlagRule/${id}`);
        navigation.navigate('/flag-rules/');
        dispatch(
            appActions.addMessage({
                message: `Flag #${id} successfully deleted`,
                type: messageTypes.SUCCESS
            })
        );
    };
}

export function updatePriority(tree) {
    return async function(dispatch) {
        await request.put(`/CommissionRuleTree/UpdatePriority`, tree);
        dispatch(setConfirmRouteChange(false));
        dispatch(
            appActions.addMessage({
                message: `Rule priorities updated!`,
                type: messageTypes.SUCCESS
            })
        );
    };
}

export function toggleRule(id, payload) {
    return async function(dispatch) {
        const message = confirmStartDate(payload.dates);
        try {
            if (payload.enabled) {
                dispatch(appActions.setConfirmationDialogue(message));
                const newDates = await message.promise;
                // Get tree if tree was not included
                if (!payload.tree) {
                    const tree = await request.get(`CommissionRuleTree/${id}`);
                    payload.tree = tree.data;
                }
                if (!isEqual(payload.dates, newDates)) {
                    await request.put(`/CommissionRuleTree/${id}`, {
                        ...payload.tree,
                        ...newDates
                    });
                }
            }
            dispatch(appActions.setDownloadProgress(0));
            await request.put(`/CommissionRuleTree/${id}/EnableOrDisable`, {
                enabled: payload.enabled
            });

            dispatch(removeCachedRoutes());
            const currentRoute = navigation.getCurrentValue();
            currentRoute.url.ignoreConfirmRouteChange = true;
            await navigation.navigate(currentRoute.url, { replace: true });
            dispatch(
                appActions.addMessage({
                    message: `Rule ${
                        payload.enabled ? 'enabled' : 'disabled'
                    }!`,
                    type: messageTypes.SUCCESS
                })
            );
            return payload.enabled;
        } catch (error) {
            dispatch(appActions.setDownloadProgress(100));
            return !payload.enabled;
        }
    };
}
export function toggleFlagRule(id, payload) {
    return async function(dispatch) {
        try {
            dispatch(appActions.setDownloadProgress(0));
            await request.put(`/InvoiceFlagRule/${id}/EnableOrDisable`, {
                enabled: payload.enabled
            });

            dispatch(removeCachedRoutes());
            const currentRoute = navigation.getCurrentValue();
            currentRoute.url.ignoreConfirmRouteChange = true;
            await navigation.navigate(currentRoute.url, { replace: true });
            dispatch(
                appActions.addMessage({
                    message: `Flag rule ${
                        payload.enabled ? 'enabled' : 'disabled'
                    }!`,
                    type: messageTypes.SUCCESS
                })
            );
            return payload.enabled;
        } catch (error) {
            dispatch(appActions.setDownloadProgress(100));
            return !payload.enabled;
        }
    };
}

export function duplicateRule(id) {
    return async function(dispatch) {
        dispatch(appActions.setDownloadProgress(0));
        dispatch(removeCachedRoutes());

        const { data: tree } = await request.get(`/CommissionRuleTree/${id}`);
        tree.description = tree.description + ' (Duplicate)';
        await request.post('/CommissionRuleTree', tree);
        const currentRoute = navigation.getCurrentValue();
        currentRoute.url.ignoreConfirmRouteChange = true;
        await navigation.navigate(currentRoute.url, { replace: true });
        dispatch(appActions.setDownloadProgress(100));
        dispatch(
            appActions.addMessage({
                message: `Rule duplicated: ${tree.description}`,
                type: messageTypes.SUCCESS
            })
        );
    };
}

export function duplicateFlag(id) {
    return async function(dispatch) {
        dispatch(appActions.setDownloadProgress(0));
        dispatch(removeCachedRoutes());

        const { data: tree } = await request.get(`/InvoiceFlagRule/${id}`);
        tree.description = tree.description + ' (Duplicate)';
        await request.post('/InvoiceFlagRule', tree);
        const currentRoute = navigation.getCurrentValue();
        currentRoute.url.ignoreConfirmRouteChange = true;
        await navigation.navigate(currentRoute.url, { replace: true });
        dispatch(appActions.setDownloadProgress(100));
        dispatch(
            appActions.addMessage({
                message: `Flag Rule duplicated: ${tree.description}`,
                type: messageTypes.SUCCESS
            })
        );
    };
}

export function getEligibleProperties(treeType, nodeType) {
    return async function(dispatch, getState) {
        const mappedFieldTypes = getState().app.mappedFieldTypes;
        const payload = {
            treeType: mappedFieldTypes[treeType].id,
            nodeType: mappedFieldTypes[nodeType].id
        };
        let result = await request.get(
            `/CommissionRuleTree/EligibleProperties?${objectToUrlParams(
                payload
            )}`
        );
        result = Object.keys(result.data.properties).reduce((accum, curr) => {
            accum.push({ label: result.data.properties[curr], value: curr });
            return accum;
        }, []);

        return result;
    };
}

export function recalculateRulesForTreeType(treeType) {
    return async function(dispatch) {
        dispatch(appActions.setDownloadProgress(0));
        await request.put(
            `/CommissionRuleTree/Recalculate?ruleTreeType=${treeType}`
        );

        dispatch(appActions.setDownloadProgress(100));
        dispatch(
            appActions.addMessage({
                message: `Rules recalculated successfully: ${treeType}`,
                type: messageTypes.SUCCESS
            })
        );
    };
}
