import React from 'react';
import TooltipTrigger from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import classes from './tooltip-dark-theme.css';
import { bool, any } from 'prop-types';

// Taken from https://www.npmjs.com/package/react-popper-tooltip
const Tooltip = ({ children, tooltip, hideArrow, darkTheme, ...props }) => (
    <TooltipTrigger
        {...props}
        tooltip={({
            arrowRef,
            tooltipRef,
            getArrowProps,
            getTooltipProps,
            placement
        }) => (
            <div
                {...getTooltipProps({
                    ref: tooltipRef,
                    className: `tooltip-container ${
                        darkTheme ? classes.tooltipDarkTheme : ''
                    }`,
                    style: { maxWidth: '20rem' }
                })}
            >
                {!hideArrow && (
                    <div
                        {...getArrowProps({
                            ref: arrowRef,
                            className: `tooltip-arrow ${
                                darkTheme ? classes.tooltipArrowDarkTheme : ''
                            }`,
                            'data-placement': placement
                        })}
                    />
                )}
                {tooltip}
            </div>
        )}
    >
        {({ getTriggerProps, triggerRef }) => (
            <span
                {...getTriggerProps({
                    ref: triggerRef,
                    className: 'trigger'
                })}
            >
                {children}
            </span>
        )}
    </TooltipTrigger>
);

Tooltip.propTypes = {
    children: any,
    tooltip: any,
    hideArrow: bool,
    darkTheme: bool
};

export default Tooltip;
